export const CONTENT0_APP_ID = process.env.REACT_APP_CONTENT0_APP_ID as string;
export const NOTION_DATABASE_ID = process.env
  .REACT_APP_NOTION_DATABASE_ID as string;
export const NOTION_STATUS_COLUMN = "Status" as const;
export const NOTION_STATUS_PUBLISHED = "published" as const;
export const ITEMS_PER_PAGE = 5;

class AppKeys {
  user = "userInfo";
  token = "accessToken";
}

class AppUrls {
  baseUrl = `https://content0-api-uyodo3tyra-uc.a.run.app/api`;
  getPosts = `/blog/${NOTION_DATABASE_ID}`;
  getPostDetail = `/blog/post`;
}

/**
 * Application constants
 */
class AppConstants {
  private static _instance: AppConstants;

  public keys = new AppKeys();
  public urls = new AppUrls();

  private constructor() {}

  public static getInstance(): AppConstants {
    if (!AppConstants._instance) {
      AppConstants._instance = new AppConstants();
    }

    return AppConstants._instance;
  }
}

export const appConstants = AppConstants.getInstance();
