import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import blogApi, { selectAllPosts } from "./api.middleware";

// select endpoint
const selectBlogEndpoint = (page: string) => (state: RootState) =>
  blogApi.endpoints.getBlog.select(page)(state);
// select endpoint data
const selectBlogEndpointData = (page: string) =>
  createSelector(selectBlogEndpoint(page), (state) => state.data);
// select blog entity state
const selectBlogEntityState = (page: string) =>
  createSelector(selectBlogEndpointData(page), (data) =>
    data ? data.posts : undefined
  );

const selectBlogPostEndpoint = (postId: string) => (state: RootState) =>
  blogApi.endpoints.getBlogPost.select(postId)(state);

export const selectBlog = (page: string) =>
  createSelector(selectBlogEntityState(page), (state) =>
    state ? selectAllPosts(state) : undefined
  );

export const selectTotalPages = (page: string) =>
  createSelector(selectBlogEndpointData(page), (state) =>
    state ? state.totalPages : 0
  );

export const selectPostDetails = (postId: string) =>
  createSelector(selectBlogPostEndpoint(postId), (state) => state.data);
