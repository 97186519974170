import { AxiosRequestConfig } from "axios";
import { CONTENT0_APP_ID } from "../config";

const requestInterceptor = (config: AxiosRequestConfig) => {
  if (!config.headers) {
    config.headers = {};
  }
  config.headers["X-APP-ID"] = CONTENT0_APP_ID;
  return config;
};

export default requestInterceptor;
