import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { blogApi, rtkQueryErrorLogger } from "./middlewares";

const rootReducer = combineReducers({
  [blogApi.reducerPath]: blogApi.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      thunk: true,
    }).concat(blogApi.middleware, rtkQueryErrorLogger);
  },
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
