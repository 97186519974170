import { isRejectedWithValue, Middleware } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { helper } from "../../../shared/utils";

const DEFAULT_ERROR =
  "An occurred has occured. Please try again later or contact the system administrator.";

const rtkQueryErrorLogger: Middleware = () => (next) => (action) => {
  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these use matchers!
  if (isRejectedWithValue(action)) {
    const message = helper.getPropertyValue(
      action,
      "payload.data",
      DEFAULT_ERROR
    );

    // error toast
    toast.error(message);
  }

  return next(action);
};

export default rtkQueryErrorLogger;
