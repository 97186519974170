import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Details from "./views/Details";
import Search from "./views/Search";

function App() {
  return (
    <div className=" font-plus-jakata">
      <Router>
        <Routes>
          <Route path="/" element={<Search />}></Route>
          <Route path="/:slug" element={<Details />}></Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
