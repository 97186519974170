import React from "react";
import ReactPaginate from "react-paginate";
import { createSearchParams, Link, useNavigate } from "react-router-dom";
import pattren from "../assets/pattren.svg";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { apiSelectors, blogApi } from "../redux/middlewares";
import { useQuery } from "../shared/hooks";
import { IPost } from "../shared/interfaces";
import { helper } from "../shared/utils";

function Search() {
  const query = useQuery(),
    navigate = useNavigate(),
    dispatch = useAppDispatch();

  const page = query.get("page") ? query.get("page") : "1",
    [searchTerm, setSearchTerm] = React.useState<string>(),
    [posts, setPosts] = React.useState<Array<IPost>>([]),
    totalPages = useAppSelector(apiSelectors.selectTotalPages(page)),
    dataSource = useAppSelector(apiSelectors.selectBlog(page));

  // fetch the blog posts for page
  React.useEffect(() => {
    dispatch(blogApi.endpoints.getBlog.initiate(page));
  }, [dispatch, page]);

  // filter by the search
  React.useEffect(() => {
    if (!dataSource) {
      return;
    }

    const posts = searchTerm
      ? dataSource.filter((post) =>
          post.title.toLowerCase().includes(searchTerm)
        )
      : [...dataSource];
    setPosts(posts);
  }, [dataSource, searchTerm]);

  // set the filter term
  const filterPosts = helper.debounce((searchTerm: string) => {
    setSearchTerm(searchTerm ? searchTerm.toLowerCase() : undefined);
  }, 500);

  // Invoke when user click to request another page.
  const handlePageClick = ({ selected }: { selected: number }) => {
    navigate({
      pathname: "/",
      search: `?${createSearchParams({ page: (selected + 1).toString() })}`,
    });
  };

  return (
    <div>
      <header
        className="flex flex-col items-center px-6 py-12 bg-cover sm:py-20 bg-secondary"
        style={{ backgroundImage: `url(${pattren})` }}
      >
        <div className="flex items-center justify-center w-24 h-24 text-4xl font-medium text-black uppercase rounded-full bg-primary">
          <svg
            width="100"
            height="100"
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M50 0L60.3956 1.09262L70.3368 4.32273L79.3893 9.54915L87.1572 16.5435L93.3013 25L97.5528 34.5492L99.7261 44.7736V55.2264L97.5528 65.4508L93.3013 75L87.1572 83.4565L79.3893 90.4508L70.3368 95.6773L60.3956 98.9074L50 100L39.6044 98.9074L29.6632 95.6773L20.6107 90.4508L12.8428 83.4565L6.69873 75L2.44717 65.4508L0.273907 55.2264V44.7736L2.44717 34.5492L6.69873 25L12.8428 16.5435L20.6107 9.54915L29.6632 4.32273L39.6044 1.09262L50 0Z"
              fill="#FFDD2B"
            />
            <path
              d="M37.038 72.648C39.81 72.648 42.276 72.054 44.436 70.866C46.596 69.642 48.288 68.004 49.512 65.952C50.772 63.864 51.402 61.524 51.402 58.932C51.402 56.376 50.862 54.108 49.782 52.128C48.702 50.112 47.172 48.528 45.192 47.376C43.248 46.224 40.944 45.648 38.28 45.648C35.724 45.648 33.618 46.17 31.962 47.214L33.204 37.98H49.89V31.77H27.966L24.78 54.126L30.774 55.476C31.278 54.324 32.088 53.442 33.204 52.83C34.356 52.218 35.634 51.912 37.038 51.912C39.09 51.912 40.764 52.56 42.06 53.856C43.356 55.152 44.004 56.844 44.004 58.932C44.004 61.02 43.356 62.73 42.06 64.062C40.764 65.358 39.09 66.006 37.038 66.006C35.382 66.006 33.87 65.556 32.502 64.656C31.134 63.756 30.144 62.406 29.532 60.606L23.16 62.874C23.664 64.89 24.6 66.636 25.968 68.112C27.372 69.588 29.028 70.722 30.936 71.514C32.88 72.27 34.914 72.648 37.038 72.648ZM70.9087 72.324C71.4487 72.324 72.0427 72.288 72.6907 72.216C73.3387 72.144 73.9147 72.072 74.4187 72V65.952C73.7347 66.06 73.1047 66.114 72.5287 66.114C70.8007 66.114 69.5407 65.718 68.7487 64.926C67.9567 64.134 67.5607 63 67.5607 61.524V48.942H74.3107V42.624H67.5607V35.928H60.4867V38.196C60.4867 39.636 60.0907 40.734 59.2987 41.49C58.5067 42.246 57.3907 42.624 55.9507 42.624H55.4107V48.942H60.4867V61.902C60.4867 65.178 61.3867 67.734 63.1867 69.57C65.0227 71.406 67.5967 72.324 70.9087 72.324Z"
              fill="black"
            />
          </svg>
        </div>

        <h2 className="mt-8 text-3xl font-bold text-center text-gray-800 capitalize sm:text-4xl">
          5 Things To Consider
        </h2>

        <p className="mt-6 text-center text-gray-500">
          Top 5 things to consider before anything and everything
        </p>
      </header>

      <form className="container flex justify-center px-6 mx-auto -mt-6 lg:-mt-8">
        <div className="w-full max-w-md lg:w-auto px-2 py-1.5 overflow-hidden shadow-md bg-white border border-transparent rounded-xl flex flex-row focus-within:ring focus-within:ring-opacity-40 focus-within:border-primary focus-within:ring-primary/20">
          <input
            className="w-full px-4 py-3 text-gray-700 placeholder-gray-400 bg-transparent outline-none lg:w-96 focus:placeholder-transparent"
            type="text"
            placeholder="Search for a decision"
            onChange={(e) => filterPosts(e.target.value)}
          />

          <button
            type="submit"
            className="flex items-center justify-center p-4 uppercase transition-colors duration-200 transform text-gray- rounded-xl bg-primary hover:bg-primary/80 focus:bg-primary/80 focus:outline-none"
          >
            <span className="sr-only">Search</span>

            <svg
              width="18"
              height="18"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.33333 12.6667C10.2789 12.6667 12.6667 10.2789 12.6667 7.33333C12.6667 4.38781 10.2789 2 7.33333 2C4.38781 2 2 4.38781 2 7.33333C2 10.2789 4.38781 12.6667 7.33333 12.6667Z"
                stroke="black"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M14.0001 14L11.1001 11.1"
                stroke="black"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>
      </form>

      <div className="container flex flex-col items-center px-6 py-8 mx-auto mt-8 space-y-6">
        {posts.map((post) => (
          <div
            key={post.id}
            className="w-full max-w-2xl p-6 bg-gray-100 rounded-2xl"
          >
            <div>
              <p className="text-sm text-gray-500 capitalize sm:text-base">
                5 Things to consider
              </p>

              <Link to={`/${post.slug}`}>
                <h3 className="flex items-center mt-2 space-x-3 text-xl font-medium text-gray-800 capitalize hover:underline hover:text-gray-600">
                  <span>{post.title}</span>
                  <svg
                    width="26"
                    height="14"
                    viewBox="0 0 26 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20.6969 1.066C20.3959 0.722005 19.8369 0.679006 19.4929 0.980006C19.1489 1.28101 19.1059 1.84001 19.4069 2.18401L22.9329 6.26901H1.00294C0.529944 6.26901 0.142944 6.65601 0.142944 7.12901C0.142944 7.60201 0.529944 7.98901 1.00294 7.98901H22.7179L19.3639 11.859C19.0629 12.203 19.1059 12.762 19.4499 13.063C19.6219 13.192 19.7939 13.278 20.0089 13.278C20.2669 13.278 20.4819 13.192 20.6539 12.977L25.8139 7.04301L20.6969 1.066Z"
                      fill="black"
                    />
                  </svg>
                </h3>
              </Link>
            </div>
          </div>
        ))}
      </div>

      {/* // pagination */}
      <div className="flex align-middle justify-center mb-8">
        <ReactPaginate
          breakLabel="..."
          nextLabel=" >>"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          pageCount={totalPages ? totalPages : 0}
          previousLabel="<< "
          renderOnZeroPageCount={null}
          pageClassName="mx-2"
          containerClassName="flex align-middle justify-center"
          previousLinkClassName="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
          pageLinkClassName="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
          nextLinkClassName="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
        />
      </div>
    </div>
  );
}

export default Search;
