import React from "react";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { apiSelectors, blogApi } from "../redux/middlewares";

function Details() {
  const params = useParams(),
    dispatch = useAppDispatch(),
    [postId, setPostId] = React.useState<string>(null),
    post = useAppSelector(apiSelectors.selectPostDetails(postId));

  React.useEffect(() => {
    if (!params.slug) {
      return;
    }

    const postId = params.slug.split("-").reverse()[0];
    if (!postId) {
      toast.error(
        "Seems like the requested url is broken. Please try the home page to view/ search the posts."
      );
      return;
    }

    setPostId(postId);
    dispatch(blogApi.endpoints.getBlogPost.initiate(postId));
  }, [dispatch, params.slug]);

  return (
    <React.Fragment>
      <header>
        <nav className=" bg-secondary">
          <div className="flex items-center justify-between max-w-4xl px-6 py-6 mx-auto">
            <Link
              to="/"
              className="flex items-center space-x-2 text-black hover:text-gray-600"
            >
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.25 9H3.75"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9 14.25L3.75 9L9 3.75"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <span>Go Back</span>
            </Link>

            <button className="flex items-center space-x-2 font-bold text-black rounded-md">
              <svg
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15 7.16669C16.3807 7.16669 17.5 6.0474 17.5 4.66669C17.5 3.28598 16.3807 2.16669 15 2.16669C13.6193 2.16669 12.5 3.28598 12.5 4.66669C12.5 6.0474 13.6193 7.16669 15 7.16669Z"
                  stroke="black"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5 13C6.38071 13 7.5 11.8807 7.5 10.5C7.5 9.11929 6.38071 8 5 8C3.61929 8 2.5 9.11929 2.5 10.5C2.5 11.8807 3.61929 13 5 13Z"
                  stroke="black"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M15 18.8333C16.3807 18.8333 17.5 17.714 17.5 16.3333C17.5 14.9526 16.3807 13.8333 15 13.8333C13.6193 13.8333 12.5 14.9526 12.5 16.3333C12.5 17.714 13.6193 18.8333 15 18.8333Z"
                  stroke="black"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M7.1582 11.7584L12.8499 15.075"
                  stroke="black"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12.8415 5.92499L7.1582 9.24165"
                  stroke="black"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <span>Share</span>
            </button>
          </div>
        </nav>

        <div className="flex flex-col items-center max-w-4xl px-6 pt-8 pb-16 mx-auto mt-4 sm:flex-row sm:space-x-6 sm:py-16">
          <div className="flex items-center justify-center w-24 h-24 text-4xl font-medium text-black uppercase rounded-full bg-primary">
            <svg
              width="100"
              height="100"
              viewBox="0 0 100 100"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M50 0L60.3956 1.09262L70.3368 4.32273L79.3893 9.54915L87.1572 16.5435L93.3013 25L97.5528 34.5492L99.7261 44.7736V55.2264L97.5528 65.4508L93.3013 75L87.1572 83.4565L79.3893 90.4508L70.3368 95.6773L60.3956 98.9074L50 100L39.6044 98.9074L29.6632 95.6773L20.6107 90.4508L12.8428 83.4565L6.69873 75L2.44717 65.4508L0.273907 55.2264V44.7736L2.44717 34.5492L6.69873 25L12.8428 16.5435L20.6107 9.54915L29.6632 4.32273L39.6044 1.09262L50 0Z"
                fill="#FFDD2B"
              />
              <path
                d="M37.038 72.648C39.81 72.648 42.276 72.054 44.436 70.866C46.596 69.642 48.288 68.004 49.512 65.952C50.772 63.864 51.402 61.524 51.402 58.932C51.402 56.376 50.862 54.108 49.782 52.128C48.702 50.112 47.172 48.528 45.192 47.376C43.248 46.224 40.944 45.648 38.28 45.648C35.724 45.648 33.618 46.17 31.962 47.214L33.204 37.98H49.89V31.77H27.966L24.78 54.126L30.774 55.476C31.278 54.324 32.088 53.442 33.204 52.83C34.356 52.218 35.634 51.912 37.038 51.912C39.09 51.912 40.764 52.56 42.06 53.856C43.356 55.152 44.004 56.844 44.004 58.932C44.004 61.02 43.356 62.73 42.06 64.062C40.764 65.358 39.09 66.006 37.038 66.006C35.382 66.006 33.87 65.556 32.502 64.656C31.134 63.756 30.144 62.406 29.532 60.606L23.16 62.874C23.664 64.89 24.6 66.636 25.968 68.112C27.372 69.588 29.028 70.722 30.936 71.514C32.88 72.27 34.914 72.648 37.038 72.648ZM70.9087 72.324C71.4487 72.324 72.0427 72.288 72.6907 72.216C73.3387 72.144 73.9147 72.072 74.4187 72V65.952C73.7347 66.06 73.1047 66.114 72.5287 66.114C70.8007 66.114 69.5407 65.718 68.7487 64.926C67.9567 64.134 67.5607 63 67.5607 61.524V48.942H74.3107V42.624H67.5607V35.928H60.4867V38.196C60.4867 39.636 60.0907 40.734 59.2987 41.49C58.5067 42.246 57.3907 42.624 55.9507 42.624H55.4107V48.942H60.4867V61.902C60.4867 65.178 61.3867 67.734 63.1867 69.57C65.0227 71.406 67.5967 72.324 70.9087 72.324Z"
                fill="black"
              />
            </svg>
          </div>

          <div className="text-center sm:text-left">
            <p className="mt-4 text-lg text-gray-500 sm:mt-0 ">
              5 Things To Consider
            </p>

            {/* post title */}
            {!!post && (
              <h2 className="mt-2 text-2xl font-bold text-gray-800 capitalize sm:text-4xl">
                {post.title}
              </h2>
            )}
          </div>
        </div>
      </header>

      <main className="max-w-4xl px-6 pt-8 pb-20 mx-auto">
        <div className="mt-6 space-y-6">
          {/* post description */}
          {!post ? (
            <div className="w-full p-4 mx-auto border border-gray-300 rounded-md shadow">
              <div className="flex space-x-4 animate-pulse">
                <div className="flex-1 py-1 space-y-6">
                  <div className="h-2 rounded bg-slate-700"></div>
                  <div className="space-y-3">
                    <div className="grid grid-cols-3 gap-4">
                      <div className="h-2 col-span-2 rounded bg-slate-700"></div>
                      <div className="h-2 col-span-1 rounded bg-slate-700"></div>
                    </div>
                    <div className="h-2 rounded bg-slate-700"></div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div
              className="prose prose-slate"
              dangerouslySetInnerHTML={{ __html: post.description }}
            ></div>
          )}
        </div>

        <hr className="my-6 border-gray-200" />

        <div className="flex justify-center">
          <button
            type="button"
            className="flex items-center justify-center px-4 py-2 space-x-2 font-medium text-black border rounded-full"
          >
            <svg
              className="w-5 h-5"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7 22H4C3.46957 22 2.96086 21.7893 2.58579 21.4142C2.21071 21.0391 2 20.5304 2 20V13C2 12.4696 2.21071 11.9609 2.58579 11.5858C2.96086 11.2107 3.46957 11 4 11H7M14 9V5C14 4.20435 13.6839 3.44129 13.1213 2.87868C12.5587 2.31607 11.7956 2 11 2L7 11V22H18.28C18.7623 22.0055 19.2304 21.8364 19.5979 21.524C19.9654 21.2116 20.2077 20.7769 20.28 20.3L21.66 11.3C21.7035 11.0134 21.6842 10.7207 21.6033 10.4423C21.5225 10.1638 21.3821 9.90629 21.1919 9.68751C21.0016 9.46873 20.7661 9.29393 20.5016 9.17522C20.2371 9.0565 19.9499 8.99672 19.66 9H14Z"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>

            <span>Thank you ! </span>
          </button>
        </div>
      </main>
    </React.Fragment>
  );
}

export default Details;
